import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            About Us
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                About Us
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Features Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-0 feature-row">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="feature-item border h-100 p-5">
                <div
                  className="btn-square bg-light rounded-circle mb-4"
                  style={{ width: "64px", height: "64px" }}
                >
                  <img className="img-fluid" src="img/global.png" alt="Icon" />
                </div>
                <h5 className="mb-3">Worldwide</h5>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="feature-item border h-100 p-5">
                <div
                  className="btn-square bg-light rounded-circle mb-4"
                  style={{ width: "64px", height: "64px" }}
                >
                  <img className="img-fluid" src="img/budget.png" alt="Icon" />
                </div>
                <h5 className="mb-3">Shipping In Budget</h5>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="feature-item border h-100 p-5">
                <div
                  className="btn-square bg-light rounded-circle mb-4"
                  style={{ width: "64px", height: "64px" }}
                >
                  <img
                    className="img-fluid"
                    src="img/container.png"
                    alt="Icon"
                  />
                </div>
                <h5 className="mb-3">Shiping anything</h5>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="feature-item border h-100 p-5">
                <div
                  className="btn-square bg-light rounded-circle mb-4"
                  style={{ width: "64px", height: "64px" }}
                >
                  <img className="img-fluid" src="img/time.png" alt="Icon" />
                </div>
                <h5 className="mb-3">Shipping Anytime</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}

      {/* About Start */}
      <div className="container-xxl about my-5">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 pt-lg-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="bg-white rounded-top p-5 mt-lg-5">
                <p className="fs-5 fw-medium text-primary">About Us</p>
                <h1 className="display-6 mb-4">Excellence in Every Shipment</h1>
                <p className="mb-4">
                  At {companyname}, we excel in providing holistic Freight
                  Forwarding and business trade solutions that empower your
                  business to reach its fullest potential. With an innate
                  understanding of both internal and external customer needs, we
                  strategically craft services that offer a comprehensive
                  solution, propelling your brand forward. Our core ethos
                  revolves around delivering operational excellence, offering
                  viable and cost-effective technology-driven solutions to even
                  the most intricate logistics and supply chain challenges. This
                  philosophy is not just a concept but a tangible reality in our
                  work, fueled by a forward-looking and progressive mindset.
                </p>

                <Link
                  className="btn btn-primary rounded-pill py-3 px-5"
                  to="/About"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      {/* Service Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div
                    className="btn-square bg-light rounded-circle mx-auto mb-4"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <img
                      className="img-fluid"
                      src="img/mission.png"
                      alt="Icon"
                    />
                  </div>
                  <h5 className="mb-3">Our Mission</h5>
                  <p className="mb-0">
                    At {companyname}, our mission is unwavering – we are
                    committed to fulfilling our promises and delivering
                    exceptional value-added services to our esteemed clients. We
                    aspire to redefine the benchmarks of quality, reliability,
                    and consistency within our industry. Through unwavering
                    integrity, steadfast reliability, and a track record of
                    meeting deadlines, we aim to build enduring relationships
                    with our partners and clients.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div
                    className="btn-square bg-light rounded-circle mx-auto mb-4"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <img
                      className="img-fluid"
                      src="img/vision.png"
                      alt="Icon"
                    />
                  </div>
                  <h5 className="mb-3">Our vision</h5>
                  <p className="mb-0">
                    Our vision is to establish {companyname} as a
                    distinguished logistics company, bolstered by a robust agent
                    network and a loyal clientele. We are dedicated to enhancing
                    your logistics efficiency as a comprehensive omnichannel
                    service provider. Our relentless commitment is to provide
                    unparalleled service and relentlessly pursue excellence,
                    establishing ourselves as the ultimate and trustworthy goods
                    forwarder.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}

      <Footer />
    </>
  );
}
