import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Dangerous Cargo
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Dangerous Cargo
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl pt-5">
        <div className="container">
          <div
            className="text-center text-md-start pb-5 pb-md-0 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <p className="fs-5 fw-medium text-primary text-center">
              Freight Solutions
            </p>
            <h1 className="display-5 text-center mb-5">Dangerous Cargo</h1>
          </div>
          <div className="row wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-12 col-md-12 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img
                  className="img-fluid"
                  src="img/Dangerous Cargo.jpg"
                  alt=""
                />
              </div>
              <div className="p-4">
                <p>
                  Ensuring the safety of both transportation modes—air and
                  sea—is of paramount importance, especially when dealing with
                  hazardous materials that pose risks to aircraft, ships, crew,
                  and passengers. Certain items fall under the category of
                  dangerous goods, and their transportation by air and sea is
                  either strictly prohibited or subject to stringent
                  regulations. To address this challenge, industry authorities
                  such as the International Air Transport Association (IATA) and
                  the International Maritime Dangerous Goods (IMDG) Code play
                  pivotal roles in advocating and implementing safe handling
                  practices for these goods.
                </p>

                <p>
                  IATA and IMDG take on the responsibility of establishing
                  comprehensive guidelines, protocols, and regulations to
                  guarantee the secure transportation of dangerous goods by air
                  and sea. They offer a wealth of technical expertise, a diverse
                  array of products, services, and specialized training programs
                  tailored to meet the exacting demands of the industry. These
                  initiatives are designed to uphold safety standards and align
                  with the unique requirements posed by the transportation of
                  hazardous materials.
                </p>

                <p>
                  Through the establishment and promotion of rigorous standards,
                  the industry has achieved an exceptional level of safety in
                  handling dangerous goods. Stringent guidelines govern
                  documentation, handling procedures, and training protocols.
                  These standards, widely endorsed and implemented, have
                  contributed to enhancing safety during the transportation of
                  hazardous materials. {companyname}, holding
                  accreditation from IATA and manned by qualified professionals
                  with Dangerous Goods certifications, stands ready to assist
                  you in safely shipping hazardous materials by air and sea, in
                  adherence to the highest safety standards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
