import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo"; 

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
            Multimodal Transportation
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Multimodal Transportation
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row">
            <div className="col-lg-10">
              <div className="blog-single-post p-4 pl-4 pr-4">
                <div  className="p-4 ">
                  <img src="assets/images/Multimodal Transportation.jpg" className="img-fluid radius-image" alt="blog-post-image" />
                </div>
                <div className="post-content px-0 border-0">
                  <h3 className="text-head-text-9 mt-4">
                  Multimodal Transportation</h3>
                  <p>When transportation is managed by a single organization, your company can achieve significant time and cost savings. Leverage our extensive network and expertise to access the perfect blend of multimodal transportation options.</p>

<p>Our flexible inland transportation services, available for both FCL (full container load) and LCL (less than full container load) shipments, encompass rail, barge, and road transport, ensuring that your goods are picked up and delivered precisely on time and to the right location.</p>

<p>Our team excels at route optimization, documentation, scheduling, and compliance with legal regulations, opening up a wealth of growth opportunities for your trade.</p>

<p>For all your multimodal transportation needs, choose {companyname} as your provider of integrated solution services.</p>

               
                </div>
                
               
               
              </div>
            </div>

          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
