import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  {
    /*  useEffect(() => {
    // Add event listener to disable right-click
    document.addEventListener('contextmenu', handleRightClick);
    
    // Cleanup: Remove event listener when component unmounts
    return () => {
      document.removeEventListener('contextmenu', handleRightClick);
    };
  }, []);

  // Function to handle the right-click event
  const handleRightClick = (e) => {
    e.preventDefault(); // Prevent the default context menu
  };
*/
  }
  return (
    <>
  {/* Topbar Start */}
  <div className="container-fluid bg-primary text-white d-none d-lg-flex">
          <div className="container py-1">
            <div className="d-flex align-items-center">
             
              <div className="ms-auto d-flex align-items-center">
                <small className="ms-4"><i className="fa fa-envelope me-3" />{companyemail}</small>
                <small className="ms-4"><i className="fa fa-phone-alt me-3" />{companynumber}</small>
               
              </div>
              <div className="ms-3 d-flex">
                  <Link className="text-white ms-2" to="/Privacy"> Privacy Policy</Link>
                  <Link className="text-white ms-2" to="/Terms"> Terms</Link>
                </div>
            </div>
          </div>
        </div>
        {/* Topbar End */}
        {/* Navbar Start */}
        <div className="container-fluid bg-white sticky-top">
          
          <div className="container">
         
            <nav className="navbar navbar-expand-lg bg-white navbar-light p-lg-0">
            <Link to="/">
                <img className="" src="img/logo.png" style={{height:"90px", paddingRight:"40px"}} ></img>
              </Link>
             
              <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav">
                  <Link to="/Home" className="nav-item nav-link active">Home</Link>
                  <Link to="/About" className="nav-item nav-link">Company Profile</Link>
                 
                  <div className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Freight Solutions</Link>
                    <div className="dropdown-menu bg-light rounded-0 rounded-bottom m-0">
                      <Link to="/Air" className="dropdown-item">Air Freight</Link>
                      <Link to="/Ocean" className="dropdown-item">Ocean Freight</Link>
                      <Link to="/Customs" className="dropdown-item">Customs Brokerage</Link>
                      <Link to="/Project" className="dropdown-item">Project Cargo Handling</Link>
                      <Link to="/Dangerous" className="dropdown-item">Dangerous Cargo</Link>
                      <Link to="/Cold" className="dropdown-item">Cold Chain Logistic</Link>                      
                    </div>
                  </div>
                  <div className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Trading Solutions</Link>
                    <div className="dropdown-menu bg-light rounded-0 rounded-bottom m-0">
                      <Link to="/Trading" className="dropdown-item">Aerospace & Aviation</Link>
                      <Link to="/Trading" className="dropdown-item">Automotive</Link>
                      <Link to="/Trading" className="dropdown-item">Beverages</Link>
                      <Link to="/Trading" className="dropdown-item">Government & Defence</Link>
                      <Link to="/Trading" className="dropdown-item">High Tech</Link>
                      <Link to="/Trading" className="dropdown-item">Industrial</Link>                      
                    </div>
                  </div>
                  <Link to="/Contact" className="nav-item nav-link">Contact</Link>
                </div>
                <div className="ms-auto  d-lg-block">
                  <Link to="/Getquote" className="btn btn-primary rounded-pill py-2 px-3">Get A Quote</Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
        {/* Navbar End */}
    </>
  );
};

export default Header;
