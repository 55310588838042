import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Ocean Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Ocean Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl pt-5">
        <div className="container">
          <div
            className="text-center text-md-start pb-5 pb-md-0 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <p className="fs-5 fw-medium text-primary text-center">
              Freight Solutions
            </p>
            <h1 className="display-5 text-center mb-5">Ocean Freight</h1>
          </div>
          <div className="row wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-12 col-md-12 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img className="img-fluid" src="img/Ocean Freight.jpg" alt="" />
              </div>
              <div className="p-4">
                <p>
                  Ocean Freight Shipping is a symbol of efficiency and
                  excellence in transporting goods to various global
                  destinations. With oceans covering a significant part of our
                  planet's surface, it naturally emerges as the most efficient
                  and accessible mode of transportation.
                </p>

                <p>
                  This shipping method is favored by many industrialized nations
                  worldwide due to its numerous advantages. Ocean freight
                  services are notably cost-effective compared to air freight
                  alternatives, striking a balance between affordability and
                  reliability. Ocean freight services are also environmentally
                  friendly, contrasting with the significant fuel consumption
                  and carbon footprint of air transportation. This makes ocean
                  shipping a sustainable choice aligned with environmental
                  concerns.
                </p>

                <p>
                  At Ocean Freight Shipping, we are a trusted and affordable
                  choice in the shipping industry. Our services cover a wide
                  range of items, ensuring that we meet the diverse needs of our
                  clients. With a focus on reliability, cost-effectiveness, and
                  environmental responsibility, we provide comprehensive and
                  efficient shipping solutions that demonstrate our commitment
                  to serving our clients and the planet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
