import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Project Cargo Handling
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Project Cargo Handling
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl pt-5">
        <div className="container">
          <div
            className="text-center text-md-start pb-5 pb-md-0 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <p className="fs-5 fw-medium text-primary text-center">
              Freight Solutions
            </p>
            <h1 className="display-5 text-center mb-5">
              Project Cargo Handling
            </h1>
          </div>
          <div className="row wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-12 col-md-12 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img
                  className="img-fluid"
                  src="img/Project Cargo Handling.jpg"
                  alt=""
                />
              </div>
              <div className="p-4">
                <p>
                  We work with passion because we believe that is the key to
                  solving our customers' most demanding situations. For us,
                  there is no “normal” request. Every customer faces unique
                  logistical considerations, challenges, and opportunities. By
                  managing all modes of transportation around the world, our
                  project cargo team has gained a wealth of practical expertise
                  and specialized know-how for dealing with logistically
                  challenging cargo and industry-specific requirements. To
                  efficiently get your cargo to your desired destinations, we
                  perform beyond the boundaries and have the courage to jump
                  into the unknown. We will work with you as a team to build and
                  manage your special project – regardless of commodity size,
                  weight, complexity, or configuration – from concept through
                  delivery.
                </p>

                <p>
                  <strong>Multimodal Transportation for Project Cargo</strong>
                  We can propose the most suitable end-to-end solution by
                  combining multiple modes, all with full visibility and
                  enhanced optimization:
                </p>

                <ul>
                  <li>Conventional vessel</li>
                  <li>Roll-on/roll-off (RORO) vessel</li>
                  <li>
                    Container break bulk loading, including oversized containers
                  </li>
                  <li>Air freight</li>
                  <li>Air and marine charter</li>
                  <li>Inland water transport by barge</li>
                  <li>Coastal marine transportation</li>
                  <li>
                    Land transportation of ultra-heavy cargo by multi-axle
                    modular trailer
                  </li>
                </ul>

                <p>
                  <strong>
                    Project Logistics Management for Project Cargo
                  </strong>
                  We can take the lead for global logistics planning using
                  control tower methodology, all with full visibility:
                </p>

                <ul>
                  <li>Full turnkey base factory relocation logistics</li>
                  <li>Project planning and project management</li>
                  <li>
                    Overall logistics control, right down to mobilizing and
                    monitoring on-site teams
                  </li>
                  <li>
                    Vendor management, with special expertise in the automotive,
                    aerospace, and renewable energy sectors
                  </li>
                  <li>Delivery and on-site erection</li>
                  <li>All necessary supporting IT solutions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
