import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  const [uname, setUname] = useState("");
  const [uemail, setUemail] = useState("");
  const [uphone, setUphone] = useState("");
  const [subject, setSubject] = useState("");
  const [uservice, setUservice] = useState("");
  const [uorigin, setUorigin] = useState("");
  const [udestination, setUdestination] = useState("");
  const [ugoods, setUgoods] = useState("");
  const [umeasurements, setUmeasurements] = useState("");
  const [ulength, setulength] = useState("");
  const [uheight, setUheight] = useState("");
  const [uwidth, setUwidth] = useState("");
  const [uweight, setUweight] = useState("");
  const [udate, setUdate] = useState("");

  const [umessage, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleUnameChange = (e) => {
    setUname(e.target.value);
  };
  const handleUemailChange = (e) => {
    setUemail(e.target.value);
  };
  const handleUphoneChange = (e) => {
    setUphone(e.target.value);
  };
  const handleuserviceChange = (e) => {
    setUservice(e.target.value);
  };
  const handleuoriginChange = (e) => {
    setUorigin(e.target.value);
  };

  const handleudestinationChange = (e) => {
    setUdestination(e.target.value);
  };
  const handlugoodsChange = (e) => {
    setUgoods(e.target.value);
  };
  const handleumeasurementsChange = (e) => {
    setUmeasurements(e.target.value);
  };
  const handleulengthChange = (e) => {
    setulength(e.target.value);
  };
  const handleuheightChange = (e) => {
    setUheight(e.target.value);
  };
  const handleuwidthChange = (e) => {
    setUwidth(e.target.value);
  };
  const handleuweightChange = (e) => {
    setUweight(e.target.value);
  };
  const handleudateChange = (e) => {
    setUdate(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      {/* Carousel Start */}
      <div className="container-fluid px-0 mb-5 w3-banner jarallax">
        <video autoPlay loop muted>
          <source src="img/video.mp4" type="video/mp4"></source>
        </video>
      </div>
      {/* Carousel End */}
      {/* Features Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-0 feature-row">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="feature-item border h-100 p-5">
                <div
                  className="btn-square bg-light rounded-circle mb-4"
                  style={{ width: "64px", height: "64px" }}
                >
                  <img className="img-fluid" src="img/global.png" alt="Icon" />
                </div>
                <h5 className="mb-3">Worldwide</h5>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="feature-item border h-100 p-5">
                <div
                  className="btn-square bg-light rounded-circle mb-4"
                  style={{ width: "64px", height: "64px" }}
                >
                  <img className="img-fluid" src="img/budget.png" alt="Icon" />
                </div>
                <h5 className="mb-3">Shipping In Budget</h5>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="feature-item border h-100 p-5">
                <div
                  className="btn-square bg-light rounded-circle mb-4"
                  style={{ width: "64px", height: "64px" }}
                >
                  <img
                    className="img-fluid"
                    src="img/container.png"
                    alt="Icon"
                  />
                </div>
                <h5 className="mb-3">Shiping anything</h5>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="feature-item border h-100 p-5">
                <div
                  className="btn-square bg-light rounded-circle mb-4"
                  style={{ width: "64px", height: "64px" }}
                >
                  <img className="img-fluid" src="img/time.png" alt="Icon" />
                </div>
                <h5 className="mb-3">Shipping Anytime</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features End */}

      {/* About Start */}
      <div className="container-xxl about my-5">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-6"></div>
            <div className="col-lg-6 pt-lg-5 wow fadeIn" data-wow-delay="0.5s">
              <div className="bg-white rounded-top p-5 mt-lg-5">
                <p className="fs-5 fw-medium text-primary">About Us</p>
                <h1 className="display-6 mb-4">Excellence in Every Shipment</h1>
                <p className="mb-4">
                  At {companyname}, we excel in providing holistic Freight
                  Forwarding and business trade solutions that empower your
                  business to reach its fullest potential. With an innate
                  understanding of both internal and external customer needs, we
                  strategically craft services that offer a comprehensive
                  solution, propelling your brand forward. Our core ethos
                  revolves around delivering operational excellence, offering
                  viable and cost-effective technology-driven solutions to even
                  the most intricate logistics and supply chain challenges. This
                  philosophy is not just a concept but a tangible reality in our
                  work, fueled by a forward-looking and progressive mindset.
                </p>

                <Link
                  className="btn btn-primary rounded-pill py-3 px-5"
                  to="/About"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      {/* Service Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div
                    className="btn-square bg-light rounded-circle mx-auto mb-4"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <img
                      className="img-fluid"
                      src="img/mission.png"
                      alt="Icon"
                    />
                  </div>
                  <h5 className="mb-3">Our Mission</h5>
                  <p className="mb-0">
                    At {companyname}, our mission is unwavering – we are
                    committed to fulfilling our promises and delivering
                    exceptional value-added services to our esteemed clients. We
                    aspire to redefine the benchmarks of quality, reliability,
                    and consistency within our industry. Through unwavering
                    integrity, steadfast reliability, and a track record of
                    meeting deadlines, we aim to build enduring relationships
                    with our partners and clients.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item position-relative h-100">
                <div className="service-text rounded p-5">
                  <div
                    className="btn-square bg-light rounded-circle mx-auto mb-4"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <img
                      className="img-fluid"
                      src="img/vision.png"
                      alt="Icon"
                    />
                  </div>
                  <h5 className="mb-3">Our vision</h5>
                  <p className="mb-0">
                    Our vision is to establish {companyname} as a
                    distinguished logistics company, bolstered by a robust agent
                    network and a loyal clientele. We are dedicated to enhancing
                    your logistics efficiency as a comprehensive omnichannel
                    service provider. Our relentless commitment is to provide
                    unparalleled service and relentlessly pursue excellence,
                    establishing ourselves as the ultimate and trustworthy goods
                    forwarder.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Service End */}
      {/* Project Start */}
      <div className="container-xxl pt-5">
        <div className="container">
          <div
            className="text-center text-md-start pb-5 pb-md-0 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <p className="fs-5 fw-medium text-primary">Our Freight Solutions</p>
            <h1 className="display-5 mb-5">
              Explore Our Freight Solutions to Your Busines Needs
            </h1>
          </div>
          <div className="row wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-4 col-md-6 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
                <div className="project-overlay">
                  <Link
                    className="btn btn-lg-square btn-light rounded-circle m-1"
                    to="/Air"
                    data-lightbox="project"
                  >
                    <i className="fa fa-eye" />
                  </Link>
                </div>
              </div>
              <div className="p-4">
                <Link className="d-block h5" to="/Air">
                  Air Freight
                </Link>
                <span> When you're faced with an international shipment that demands
                  swift delivery within tight deadlines, look no further than{" "}
                  {companyname} direct freight services.</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img className="img-fluid" src="img/Ocean Freight.jpg" alt="" />
                <div className="project-overlay">
                  <Link
                    className="btn btn-lg-square btn-light rounded-circle m-1"
                    to="/Ocean"
                    data-lightbox="project"
                  >
                    <i className="fa fa-eye" />
                  </Link>
                </div>
              </div>
              <div className="p-4">
                <Link className="d-block h5" to="/Ocean">
                  Ocean Freight
                </Link>
                <span> Ocean Freight Shipping is a symbol of efficiency and
                  excellence in transporting goods to various global
                  destinations. </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img
                  className="img-fluid"
                  src="img/Customs Brokerage.jpg"
                  alt=""
                />
                <div className="project-overlay">
                  <Link
                    className="btn btn-lg-square btn-light rounded-circle m-1"
                    to="/Customs"
                    data-lightbox="project"
                  >
                    <i className="fa fa-eye" />
                  </Link>
                </div>
              </div>
              <div className="p-4">
                <Link className="d-block h5" to="/Customs">
                  Customs Brokerage
                </Link>
                <span>International trade operates within a framework of diverse
                  trade laws and tariffs, strategically established by nations
                  to regulate the flow of goods across borders. In the intricate
                  landscape of global trade, </span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img
                  className="img-fluid"
                  src="img/Project Cargo Handling.jpg"
                  alt=""
                />
                <div className="project-overlay">
                  <Link
                    className="btn btn-lg-square btn-light rounded-circle m-1"
                    to="/Project"
                    data-lightbox="project"
                  >
                    <i className="fa fa-eye" />
                  </Link>
                </div>
              </div>
              <div className="p-4">
                <Link className="d-block h5" to="/Project">
                  Project Cargo Handling
                </Link>
                <span> We work with passion because we believe that is the key to
                  solving our customers' most demanding situations. For us,
                  there is no “normal” request. Every customer faces unique
                  logistical considerations, challenges, and opportunities.</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img
                  className="img-fluid"
                  src="img/Dangerous Cargo.jpg"
                  alt=""
                />
                <div className="project-overlay">
                  <Link
                    className="btn btn-lg-square btn-light rounded-circle m-1"
                    to="/Dangerous"
                    data-lightbox="project"
                  >
                    <i className="fa fa-eye" />
                  </Link>
                </div>
              </div>
              <div className="p-4">
                <Link className="d-block h5" to="/Dangerous">
                  Dangerous Cargo
                </Link>
                <span> Ensuring the safety of both transportation modes—air and
                  sea—is of paramount importance, especially when dealing with
                  hazardous materials that pose risks to aircraft, ships, crew,
                  and passengers.</span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img
                  className="img-fluid"
                  src="img/Cold Chain Logistic.jpg"
                  alt=""
                />
                <div className="project-overlay">
                  <Link
                    className="btn btn-lg-square btn-light rounded-circle m-1"
                    to="/Cold"
                    data-lightbox="project"
                  >
                    <i className="fa fa-eye" />
                  </Link>
                </div>
              </div>
              <div className="p-4">
                <Link className="d-block h5" to="/Cold">
                  Cold Chain Logistic
                </Link>
                <span> The cold chain constitutes a critical aspect of modern supply
                  chains, encompassing the meticulous transport of
                  temperature-sensitive commodities. This intricate process
                  relies on an intricate interplay of thermal and refrigerated
                  packaging techniques</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Project End */}
      {/* Quote Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
              <p className="fs-5 fw-medium text-primary">Get A Quote</p>
              <h1 className="display-5 mb-4">
                Need Any Expert Help? We're Here!
              </h1>
              <p>
                At {companyname}, our mission is to provide transformative
                transportation and logistics solutions that play a pivotal role
                in the success of the businesses and individuals we serve. Our
                foundation is built upon three fundamental values: an unwavering
                commitment to our customers, an unwavering dedication to
                excellence, and a constant pursuit of innovative thinking.
                Whether your business is small or large, we offer tailored
                leasing solutions that adapt to your distinctive and
                ever-evolving requirements
              </p>
              <Link
                className="d-inline-flex align-items-center rounded overflow-hidden border border-primary"
                to
              >
                <span
                  className="btn-lg-square bg-primary"
                  style={{ width: "55px", height: "55px" }}
                >
                  <i className="fa fa-phone-alt text-white" />
                </span>
                <span className="fs-5 fw-medium mx-4">{companynumber}</span>
              </Link>
            </div>
            <div className="col-lg-8 wow fadeInUp" data-wow-delay="0.5s">
              <h2 className="mb-4">Get A Free Quote</h2>
              <div className="row g-3">
                <form
                  action="/php/thankyou-getquote.php"
                  method="post"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="comment-form__input-box">
                        <input
                          className="form-control"
                          value={uname}
                          onChange={(event) => handleUnameChange(event)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          type="text"
                          name="uname"
                          id="uname"
                          placeholder="Enter your Fullname"
                          required="Please Enter the Fullname"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="comment-form__input-box">
                        <input
                          className="form-control"
                          value={uemail}
                          onChange={(event) => handleUemailChange(event)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          type="email"
                          name="uemail"
                          id="uemail"
                          placeholder="Enter Your Email"
                          required="Please Enter Your Email"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="comment-form__input-box">
                        <input
                          className="form-control"
                          value={uphone}
                          onChange={(event) => handleUphoneChange(event)}
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          type="text"
                          name="uphone"
                          id="uphone"
                          placeholder="Enter Phone Number"
                          required="Please Enter Phone Number"
                        />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="comment-form__input-box">
                        <input
                          className="form-control"
                          value={subject}
                          onChange={(event) => handlesubjectChange(event)}
                          type="text"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          id="subject"
                          name="subject"
                          placeholder="Enter subject"
                        />
                      </div>
                    </div>
                    <div className="col-12" style={{ padding: "10px" }}>
                      <select
                        className="form-control"
                        name="uservice"
                        id="uservice"
                        value={uservice}
                        onChange={(event) => handleuserviceChange(event)}
                        style={{
                          outline: "none",
                          color: "#333",
                          fontSize: "14px",
                          textTransform: "capitalize",
                          letterSpacing: "2px",
                          width: "100%",
                          border: "1px solid #ddd",
                          textAlign: "left",
                          background: "#F9FAFF",
                        }}
                      >
                        <option selected>Service Required</option>
                        <option value="Air Freight Forwarding">
                          Air Freight Forwarding
                        </option>
                        <option value="Ocean Freight Forwarding">
                          Ocean Freight Forwarding
                        </option>
                        <option value="Project Cargo">Project Cargo</option>
                      </select>
                    </div>
                    <div className="comment-form__input-box col-lg-4">
                      <input
                        className="form-control"
                        value={uorigin}
                        onChange={(event) => handleuoriginChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="uorigin"
                        id="uorigin"
                        placeholder="Origin City"
                      />
                    </div>
                    <div className="comment-form__input-box col-lg-4">
                      <input
                        className="form-control"
                        value={udestination}
                        onChange={(event) => handleudestinationChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="udestination"
                        id="udestination"
                        placeholder="Destination City"
                      />
                    </div>
                    <div className="comment-form__input-box col-lg-4">
                      <input
                        className="form-control"
                        value={ugoods}
                        onChange={(event) => handlugoodsChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="ugoods"
                        id="ugoods"
                        placeholder="Types Of Goods"
                      />
                    </div>
                    <div className="col-12" style={{ padding: "10px" }}>
                      <select
                        className="form-control"
                        value={umeasurements}
                        onChange={(event) => handleumeasurementsChange(event)}
                        name="umeasurements"
                        id="umeasurements"
                        style={{
                          outline: "none",
                          color: "#333",
                          fontSize: "14px",
                          textTransform: "capitalize",
                          letterSpacing: "2px",
                          width: "100%",
                          border: "1px solid #ddd",
                          textAlign: "left",
                          background: "#F9FAFF",
                        }}
                      >
                        <option selected>Measurements Below</option>
                        <option value="Metric(Kgs/cms)">Metric(Kgs/cms)</option>
                        <option value="Imperial(lbs/in)">
                          Imperial(lbs/in)
                        </option>
                      </select>
                    </div>
                    <div className="comment-form__input-box col-lg-6">
                      <input
                        className="form-control"
                        value={ulength}
                        onChange={(event) => handleulengthChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="ulength"
                        id="ulength"
                        placeholder="Length"
                      />
                    </div>
                    <div className="comment-form__input-box col-lg-6">
                      <input
                        className="form-control"
                        value={uheight}
                        onChange={(event) => handleuheightChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="uheight"
                        id="uheight"
                        placeholder="Height"
                      />
                    </div>
                    <div className="comment-form__input-box col-lg-6">
                      <input
                        className="form-control"
                        value={uwidth}
                        onChange={(event) => handleuwidthChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="uwidth"
                        id="uwidth"
                        placeholder="Width"
                      />
                    </div>
                    <div className="comment-form__input-box col-lg-6">
                      <input
                        className="form-control"
                        value={uweight}
                        onChange={(event) => handleuweightChange(event)}
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        type="text"
                        name="uweight"
                        id="uweight"
                        placeholder="weight"
                      />
                    </div>
                    <div className="col-12 " style={{ padding: "10px" }}>
                      <label>Date Required at destination</label>
                      <div
                        className="date"
                        id="date"
                        data-target-input="nearest"
                      >
                        <input
                          className="form-control"
                          value={udate}
                          onChange={(event) => handleudateChange(event)}
                          type="date"
                          name="udate"
                          id="udate"
                          placeholder="Date required at destination"
                          style={{ height: "55px", width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="comment-form__input-box">
                        <textarea
                          className="form-control"
                          style={{
                            width: "100%",
                            padding: "10px",
                            margin: "6px 0",
                          }}
                          value={umessage}
                          onChange={(event) => handlemessageChange(event)}
                          name="umessage"
                          id="umessage"
                          placeholder="Write Message"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      name="submitquote"
                      defaultValue="SEND"
                      className="btn btn-primary py-3 px-5  "
                    >
                      Send Request
                    </button>
                  </div>
                  <br />
                </form>
                <h5 style={{ color: "red" }}>{result}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Quote Start */}
      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <p className="fs-5 fw-medium text-primary">Our Trading Solutions</p>
            <h1 className="display-5 mb-5">
              Trading Solutions We Work With
            </h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item rounded overflow-hidden pb-4">
                <img
                  className="img-fluid mb-4"
                  src="img/Aerospace & Aviation.jpg"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <h5>Aerospace & Aviation</h5>
                  <span className="text-primary">Trading </span>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item rounded overflow-hidden pb-4">
                <img
                  className="img-fluid mb-4"
                  src="img/Automotive.jpg"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <h5>Automotive</h5>
                  <span className="text-primary">Trading </span>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item rounded overflow-hidden pb-4">
                <img
                  className="img-fluid mb-4"
                  src="img/Beverages.jpg"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <h5>Beverages</h5>
                  <span className="text-primary">Trading </span>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item rounded overflow-hidden pb-4">
                <img
                  className="img-fluid mb-4"
                  src="img/Government & Defence.jpg"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <h5>Government & Defence</h5>
                  <span className="text-primary">Trading </span>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item rounded overflow-hidden pb-4">
                <img
                  className="img-fluid mb-4"
                  src="img/High Tech.jpg"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <h5>High Tech</h5>
                  <span className="text-primary">Trading </span>
                </Link>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item rounded overflow-hidden pb-4">
                <img
                  className="img-fluid mb-4"
                  src="img/Industrial.jpg"
                  alt=""
                />
                <Link to="/Trading">
                  {" "}
                  <h5>Industrial</h5>
                  <span className="text-primary">Trading </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}
