import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Air Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Air Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl pt-5">
        <div className="container">
          <div
            className="text-center text-md-start pb-5 pb-md-0 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <p className="fs-5 fw-medium text-primary text-center">
              Freight Solutions
            </p>
            <h1 className="display-5 text-center mb-5">Air Freight</h1>
          </div>
          <div className="row wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-12 col-md-12 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img className="img-fluid" src="img/Air Freight.jpg" alt="" />
              </div>
              <div className="p-4">
                <p>
                  When you're faced with an international shipment that demands
                  swift delivery within tight deadlines, look no further than{" "}
                  {companyname} direct freight services. Our direct approach
                  ensures that your cargo is promptly loaded onto the earliest
                  available flight, bypassing the need for consolidation. This
                  option proves highly advantageous for those with larger
                  shipments or time-critical deliveries, guaranteeing the prompt
                  arrival of your goods.
                </p>

                <p>
                  For shippers who are conscious of both time and cost factors,
                  we offer consolidated services. This involves combining cargo
                  from multiple shippers into a single load under a master air
                  waybill, streamlining resources and processes for efficiency.
                  If you're uncertain about whether direct shipping is the right
                  fit for your requirements, our team is on hand to discuss your
                  needs and help you determine the optimal method for
                  transporting your cargo.
                </p>

                <p>
                  At {companyname}, we understand that each shipment is unique,
                  and our services are designed to cater to your specific
                  demands. Whether your priority is speed or cost-effectiveness,
                  our range of shipping solutions ensures a tailored approach
                  that aligns with your goals. We are committed to providing
                  reliable and effective logistics solutions that address your
                  diverse shipping needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
