import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Cold Chain Logistic
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Cold Chain Logistic
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl pt-5">
        <div className="container">
          <div
            className="text-center text-md-start pb-5 pb-md-0 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <p className="fs-5 fw-medium text-primary text-center">
              Freight Solutions
            </p>
            <h1 className="display-5 text-center mb-5">Cold Chain Logistic</h1>
          </div>
          <div className="row wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-12 col-md-12 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img
                  className="img-fluid"
                  src="img/Cold Chain Logistic.jpg"
                  alt=""
                />
              </div>
              <div className="p-4">
                <p>
                  The cold chain constitutes a critical aspect of modern supply
                  chains, encompassing the meticulous transport of
                  temperature-sensitive commodities. This intricate process
                  relies on an intricate interplay of thermal and refrigerated
                  packaging techniques, coupled with strategic logistical
                  planning, all aimed at safeguarding the pristine quality of
                  these shipments. The paramount objective is to preserve the
                  integrity of these products throughout their journey.
                </p>

                <p>
                  Cold chain management entails a variety of methods to ensure
                  the safe passage of temperature-sensitive items. These include
                  the utilization of refrigerated trucks and railcars, which
                  provide controlled environments during land transportation.
                  Similarly, refrigerated cargo ships facilitate the sea
                  transportation of perishable goods, while air cargo services
                  offer swift and efficient air travel for maintaining optimal
                  conditions.
                </p>

                <p>
                  The implementation of a robust cold chain is essential across
                  industries that deal with products susceptible to temperature
                  fluctuations. Industries like pharmaceuticals, food and
                  beverages, and certain chemicals heavily rely on the cold
                  chain to guarantee that their goods remain unaltered during
                  transit. Ensuring the successful cold chain execution demands
                  a harmonious orchestration of technologies, regulations, and
                  meticulous oversight, all working together to uphold the
                  quality and safety of temperature-sensitive commodities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
