import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Customs Brokerage
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Customs Brokerage
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <div className="container-xxl pt-5">
        <div className="container">
          <div
            className="text-center text-md-start pb-5 pb-md-0 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <p className="fs-5 fw-medium text-primary text-center">
              Freight Solutions
            </p>
            <h1 className="display-5 text-center mb-5">Customs Brokerage</h1>
          </div>
          <div className="row wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-12 col-md-12 wow fadeInUp project-item mb-5">
              <div className="position-relative">
                <img
                  className="img-fluid"
                  src="img/Customs Brokerage.jpg"
                  alt=""
                />
              </div>
              <div className="p-4">
                <p>
                  International trade operates within a framework of diverse
                  trade laws and tariffs, strategically established by nations
                  to regulate the flow of goods across borders. In the intricate
                  landscape of global trade, our services emerge as a beacon of
                  understanding and efficiency. With a deep understanding of the
                  customs brokerage process in India and worldwide through our
                  extensive network of partners, we navigate the complexities of
                  international trade on behalf of our clients.
                </p>

                <p>
                  Each nation defines its distinct set of regulations that
                  govern the import and export of goods, constituting a critical
                  facet of the trade landscape. In the dynamic realm of global
                  trade, these regulations are subject to frequent amendments
                  and updates. As adept clearance specialists, we shoulder the
                  responsibility of meticulously overseeing the movement of
                  goods, adeptly managing the import and export processes on
                  behalf of our valued clients.
                </p>

                <p>
                  Our expertise shines through as we harmonize the intricate
                  interplay of regulations, documentation, and logistics to
                  ensure seamless and compliant trade operations. With a
                  commitment to excellence, we act as the conduit that bridges
                  international trade intricacies, enabling our clients to
                  engage in cross-border commerce with confidence and
                  efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
