import { useState } from "react";
import { useHistory } from "react-router-dom";
import Getquote from "../pages/Getquote";

import $ from "jquery";
import Header from "../components/Header";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  const [subemail, setSubemail] = useState("");
  const [result, setResult] = useState("");

  const handleSubemailChange = (e) => {
    setSubemail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      {/* Footer Start */}
      <div
        className="container-fluid bg-dark footer mt-5 py-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Our Office</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3" />
                {companyaddress}
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                {companynumber}
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3" />
                {companyemail}
              </p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Quick Links</h4>
              <Link className="btn btn-link ps-0" to="/Home">
                Home
              </Link>
              <Link className="btn btn-link ps-0" to="/About">
                About Us
              </Link>

              <Link className="btn btn-link ps-0" to="/Trading">
                Trading Services
              </Link>
              <Link className="btn btn-link ps-0" to="/Contact">
                Contact
              </Link>
              <Link className="btn btn-link ps-0" to="/Getquote">
                Getquote
              </Link>
              <Link className="btn btn-link ps-0" to="/Privacy">
                Privacy Policy
              </Link>
              <Link className="btn btn-link ps-0" to="/Terms">
                Terms
              </Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Quick Links</h4>
              <Link to="/Air" className="btn btn-link">Air Freight</Link>
                      <Link to="/Ocean" className="btn btn-link">Ocean Freight</Link>
                      <Link to="/Customs" className="btn btn-link">Customs Brokerage</Link>
                      <Link to="/Project" className="btn btn-link">Project Cargo Handling</Link>
                      <Link to="/Dangerous" className="btn btn-link">Dangerous Cargo</Link>
                      <Link to="/Cold" className="btn btn-link">Cold Chain Logistic</Link>
            </div>
            <div className="col-lg-3 col-md-6">
              <h4 className="text-white mb-4">Newsletter</h4>
              <p>Subscribe to Our Newsletter</p>
              <div className="position-relative w-100">
                <input
                  className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                  type="text"
                  placeholder="Your email"
                />
                <button
                  type="button"
                  className="btn btn-light py-2 position-absolute top-0 end-0 mt-2 me-2"
                >
                  SignUp
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
      {/* Copyright Start */}
      <div className="container-fluid copyright py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©2023{" "}
              <Link className="fw-medium text-light" to="/">
                {companyname}
              </Link>
              , All Right Reserved.
            </div>
            <div className="col-md-6 text-center text-md-end">
             
            </div>
          </div>
        </div>
      </div>
      {/* Copyright End */}
    </>
  );
};

export default Footer;
