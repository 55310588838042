import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
            Services
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Services
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* Page Title End */}

      {/* courses section */}
      <section className="w3l-courses py-5" id="courses">
        <div className="container pb-lg-5 pb-md-4 pb-2">
          <h5 className="sub-title text-center">
            Our Freight Forwarding Services
          </h5>
          <h3 className="title-style text-center">Services</h3>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 item mt-5">
              <div className="card">
                <div className="card-header p-0 position-relative">
                  <Link to="/Sea" className="zoom d-block">
                    <img
                      className="card-img-bottom d-block"
                      src="assets/images/Sea Freight Forwarding.jpg"
                      alt="Card image cap"
                    />
                  </Link>
                </div>
                <div className="card-body course-details">
                  <div className="price-review d-flex justify-content-between mb-1align-items-center">
                    <Link to="/Sea">
                      {" "}
                      <p>Sea Freight Forwarding</p>
                    </Link>
                  </div>
                  <Link className="course-desc">content</Link>
                  <div className="course-meta mt-4"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item mt-5">
              <div className="card">
                <div className="card-header p-0 position-relative">
                  <Link to="/Air" className="zoom d-block">
                    <img
                      className="card-img-bottom d-block"
                      src="assets/images/Air Freight Forwarding.jpg"
                      alt="Card image cap"
                    />
                  </Link>
                </div>
                <div className="card-body course-details">
                  <div className="price-review d-flex justify-content-between mb-1align-items-center">
                    <Link to="/Air">
                      {" "}
                      <p>Air Freight Forwarding</p>
                    </Link>
                  </div>
                  <Link className="course-desc">content</Link>
                  <div className="course-meta mt-4"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 item mt-5">
              <div className="card">
                <div className="card-header p-0 position-relative">
                  <Link to="/Surface" className="zoom d-block">
                    <img
                      className="card-img-bottom d-block"
                      src="assets/images/Surface Transportation.jpg"
                      alt="Card image cap"
                    />
                  </Link>
                </div>
                <div className="card-body course-details">
                  <div className="price-review d-flex justify-content-between mb-1align-items-center">
                    <Link to="/Surface">
                      {" "}
                      <p>Surface Transportation</p>
                    </Link>
                  </div>
                  <Link className="course-desc">content</Link>
                  <div className="course-meta mt-4"></div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </section>
      <section className="w3l-blog py-5" id="blog">
        <div className="container py-lg-5 py-md-4 py-2">
          <h5 className="sub-title text-center">Services</h5>
          <h3 className="title-style text-center">Our Other Services</h3>
          <div className="row inner-sec-w3ls justify-content-center">
            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Warehousing" className="d-block">
                    <img
                      src="assets/images/Warehousing & Distribution.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Warehousing">Warehousing & Distribution</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Multimodal" className="d-block">
                    <img
                      src="assets/images/Multimodal Transportation.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Multimodal">Multimodal Transportation</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Projects" className="d-block">
                    <img
                      src="assets/images/Projects & Break Bulk.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Projects">Projects & Break Bulk</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Customs" className="d-block">
                    <img
                      src="assets/images/Customs Clearance.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Customs">Customs Clearance</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 about-in blog-grid-info text-left mt-5">
              <div className="card img">
                <div className="card-body img">
                  <Link to="/Hazardous" className="d-block">
                    <img
                      src="assets/images/Hazardous Cargo.jpg"
                      alt=""
                      className="img-fluid radius-image"
                    />
                  </Link>
                  <div className="blog-des">
                    <ul className="admin-post mb-3"></ul>
                    <h5 className="card-title mb-3">
                      <Link to="/Hazardous">Hazardous Cargo</Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="w3l-team-grids-sec py-5" id="team">
        <div className="container pb-lg-5 pb-md-4 pb-2">
          <h5 className="sub-title text-center">Trading Solutions</h5>
          <h3 className="title-style text-center">
            {" "}
            Trading Solutions We Provide
          </h3>
          <div className="row cards justify-content-center">
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Healthcare.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Healthcare</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Retail.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Retail</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Construction.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Construction</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Consumer Electronics.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Consumer Electronics</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Direct to Consumer.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Direct to Consumer</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-md-6 mt-5">
              <Link to="/Trading" className="card">
                <img
                  src="assets/images/Marketplace & Sellers.jpg"
                  className="card__image radius-image"
                  style={{ height: "260px" }}
                  alt=""
                />
                <div className="card__overlay">
                  <div className="card__header">
                    <div className="card__header-text">
                      <h3 className="card__title">Marketplace & Sellers</h3>
                      <span className="card__status">Trading Solutions</span>
                    </div>
                  </div>
                  <p className="card__description">content</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
