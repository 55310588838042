import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <section className="inner-banner py-5">
        <div className="w3l-breadcrumb py-lg-5">
          <div className="container pt-5 pb-sm-4 pb-2">
            <h4 className="inner-text-title font-weight-bold pt-5">
              Surface Transportation
            </h4>
            <ul className="breadcrumbs-custom-path">
              <li>
                <Link to="/Home">Home</Link>
              </li>
              <li className="active">
                <i className="fas fa-angle-right mx-2" />
                Surface Transportation
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog py-5">
        <div className="container py-lg-5 py-md-4 py-2">
          <div className="row">
            <div className="col-lg-10">
              <div className="blog-single-post p-4 pl-4 pr-4">
                <div className="p-4 ">
                  <img
                    src="assets/images/Surface Transportation.jpg"
                    className="img-fluid radius-image"
                    alt="blog-post-image"
                  />
                </div>
                <div className="post-content px-0 border-0">
                  <h3 className="text-head-text-9 mt-4">
                    Surface Transportation
                  </h3>
                  <p>
                    Our land transport services are meticulously tailored to
                    meet the precise requirements of our clients. We provide you
                    with the assurance of dependable budgeting and efficient
                    goods transfer by clearly defining transit timeframes.
                  </p>

                  <p>
                    Operating with a commitment to care and perfection, we
                    ensure the complete safety of your product transportation
                    using our own fleet of containers and trailers. Our services
                    are designed to deliver your items in pristine condition
                    quickly and reliably.
                  </p>

                  <p>
                    With the safety of your cargo as our paramount concern, we
                    take pride in being a client-focused service provider.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
