import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Pages() {
  return (
    <>
      <Header />
      <div
        className="container-fluid page-header py-5 mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center py-5">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Trading Solutions
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Trading Solutions
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* About Start */}
      <div className="container-fluid py-5">
  <div className="container py-5">
    <div className="row g-5">
      <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".3s">
        <div className="about-img pt-4 mt-4">
          <div className="rotate-left bg-dark" />
          <div className="rotate-right bg-dark" />
          <img
            src="img/Aerospace & Aviation.jpg"
            className="img-fluid h-100"
            alt="img"
          />
        </div>
      </div>
      <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".6s">
        <div className="about-item overflow-hidden">
          <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
            Trading Solutions
          </h5>
          <h1 className="display-5 mb-2">Aerospace & Aviation</h1>
          <p className="fs-7" style={{ textAlign: "justify" }}>
            <p>
              {companyname} plays a vital role in strengthening the entire
              aircraft manufacturing supply chain, providing consistent support
              across various stages, from sourcing and procurement to the
              replenishment of critical aerospace components. Our global network
              of facilities operates around the clock, meticulously coordinating
              timely product deliveries to destinations worldwide.
            </p>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="container py-5">
    <div className="row g-5">
      <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".6s">
        <div className="about-item overflow-hidden">
          <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
            Trading Solutions
          </h5>
          <h1 className="display-5 mb-2">Automotive</h1>
          <p className="fs-7" style={{ textAlign: "justify" }}>
            <p>
              A significant aspect of our service is Purchase Order (PO) Management.
              Our advanced, in-house developed PO Management System provides you
              with real-time insights into the status of your containers—tracking
              their movements, storage, and arrivals. Whether you're at home or
              in the office, accessing this information is effortless, ensuring
              you remain well-informed.
            </p>
          </p>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".3s">
        <div className="about-img pt-4 mt-4">
          <div className="rotate-left bg-dark" />
          <div className="rotate-right bg-dark" />
          <img
            src="img/Automotive.jpg"
            className="img-fluid h-100"
            alt="img"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div className="container py-5">
  <div className="row g-5">
    <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".3s">
      <div className="about-img pt-4 mt-4">
        <div className="rotate-left bg-dark" />
        <div className="rotate-right bg-dark" />
        <img
          src="img/Beverages.jpg"
          className="img-fluid h-100"
          alt="img"
        />
      </div>
    </div>
    <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".6s">
      <div className="about-item overflow-hidden">
        <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
          Trading Solutions
        </h5>
        <h1 className="display-5 mb-2">Beverages</h1>
        <p className="fs-7" style={{ textAlign: "justify" }}>
          <p>
            In the beverage industry, {companyname} delivers excellence with
            specialized logistics and storage solutions that ensure the quality of
            your beverages remains uncompromised. With a comprehensive range of
            reliable multi-modal services tailored for every type of beverage, we
            guarantee the preservation of quality throughout the journey. Our
            skilled teams, strategically positioned worldwide, efficiently manage
            deliveries that not only meet but exceed expectations, leaving your
            customers with a favorable and satisfying experience.
          </p>
        </p>
      </div>
    </div>
  </div>
</div>
<div className="container py-5">
  <div className="row g-5">
    <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".6s">
      <div className="about-item overflow-hidden">
        <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
          Trading Solutions
        </h5>
        <h1 className="display-5 mb-2">Government & Defence</h1>
        <p className="fs-7" style={{ textAlign: "justify" }}>
          <p>
            Our team at {companyname} takes meticulous measures to ensure
            the seamless and secure loading and unloading of your cargo at your
            designated site. Prior to the operation, we meticulously prepare to
            ensure a smooth and successful process. We understand the critical
            importance of this stage, and our expertise in cargo handling shines
            as we implement the necessary protocols to ensure a problem-free
            operation.
          </p>
        </p>
      </div>
    </div>
    <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".3s">
      <div className="about-img pt-4 mt-4">
        <div className="rotate-left bg-dark" />
        <div className="rotate-right bg-dark" />
        <img
          src="img/Government & Defence.jpg"
          className="img-fluid h-100"
          alt="img"
        />
      </div>
    </div>
  </div>
</div>
<div className="container py-5">
  <div className="row g-5">
    <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".3s">
      <div className="about-img pt-4 mt-4">
        <div className="rotate-left bg-dark" />
        <div className="rotate-right bg-dark" />
        <img
          src="img/High Tech.jpg"
          className="img-fluid h-100"
          alt="img"
        />
      </div>
    </div>
    <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".6s">
      <div className="about-item overflow-hidden">
        <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
          Trading Solutions
        </h5>
        <h1 className="display-5 mb-2">High Tech</h1>
        <p className="fs-7" style={{ textAlign: "justify" }}>
          <p>
            {companyname} delivers comprehensive solutions covering the
            entire supply chain, specializing in tailored services for the
            consumer electronics industry. Our expertise spans a wide range of
            requirements, allowing us to effectively address your unique
            logistical needs. Whether you require streamlined inbound air and
            ocean consolidation solutions, efficient DC bypass programs, seamless
            Ecommerce fulfillment, or precise retail store distribution,
            {companyname} serves as your dedicated outsourced logistics
            partner.
          </p>
        </p>
      </div>
    </div>
  </div>
</div>
<div className="container py-5">
  <div className="row g-5">
    <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".6s">
      <div className="about-item overflow-hidden">
        <h5 className="mb-2 px-3 py-1 text-dark rounded-pill d-inline-block border border-2 border-primary">
          Trading Solutions
        </h5>
        <h1 className="display-5 mb-2">Industrial</h1>
        <p className="fs-7" style={{ textAlign: "justify" }}>
          <p>
            {companyname} offers a comprehensive range of services tailored
            for the Aerospace and Aviation sectors, covering a global scope of
            support for aircraft manufacturing logistics. Our expertise extends
            to both civilian and military aircraft operations, delivering
            top-tier fulfillment logistics solutions. Whether dealing with the
            intricacies of large-scale engine components or navigating the
            urgency of same-day transportation needs, {companyname} is your
            dedicated partner.
          </p>
        </p>
      </div>
    </div>
    <div className="col-lg-6 col-md-12 wow fadeInUp" data-wow-delay=".3s">
      <div className="about-img pt-4 mt-4">
        <div className="rotate-left bg-dark" />
        <div className="rotate-right bg-dark" />
        <img
          src="img/Industrial.jpg"
          className="img-fluid h-100"
          alt="img"
        />
      </div>
    </div>
  </div>
</div>

      {/* About End */}
      <Footer />
    </>
  );
}
